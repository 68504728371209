import { Transition } from "@headlessui/react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useState } from "react";
import Stars from "../../components/commons/stars";
import Layout from "../../components/layout/layout";
import {
  getTranslatedIfAvail,
  parseStory,
  useUrls,
} from "../../components/utils";
import { LanguageContext } from "../../context/language-context";
import { StoreContext } from "../../context/store-context";
import PageHeader from "../page-header";

const AccessoriPage = ({ data }) => {
  const accessori = parseStory(data?.pagina).content.accessori;
  console.log({ descrizioni: accessori, pro: data?.shopify });
  const [filtered, setFiltered] = useState(accessori.map((a) => false));
  const showAll = !filtered.some((f) => f);
  const [prodotti, setProdotti] = useState({});
  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  useEffect(() => {
    const sb = (data?.sb?.nodes ?? []).reduce((obj, story) => {
      obj[story.slug] = parseStory(story).content;
    }, {});

    const p = accessori.reduce((obj, acc) => {
      obj[acc.tag] = (data?.shopify?.nodes ?? [])
        .filter((p) => p.tags.includes(acc.tag))
        .map((p) => ({ ...p, sb: sb[p.handle] }));
      return obj;
    }, {});

    setProdotti(p);
    console.log(p);
  }, [data]);

  const toggleFilter = (i) => {
    setFiltered((old) => {
      const n = [...old];
      n[i] = !n[i];
      return n;
    });
  };
  return (
    <Layout>
      <PageHeader
        tit={t["acc-tit"]}
        sub={t["acc-occhiello"]}
        bg="bg-ci-green"
      />
      <div className="ci-cont">
        <div className="mt-16 flex gap-4 items-center">
          <p className="mr-3">{t["filtra-per"]}</p>
          {accessori.map((c, i) => (
            <button
              className={
                "py-3.5 px-5 capitalize " +
                (filtered[i] ? "ci-bs-sel" : "ci-bs")
              }
              key={c.nome}
              onClick={() => {
                toggleFilter(i);
              }}
            >
              {c.nome}
            </button>
          ))}
        </div>
        {accessori.map((c, i) => (
          <Transition
            show={filtered[i] || showAll}
            unmount={false}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            key={c.nome}
            className="my-12"
          >
            <h2 className="capitalize text-center ci-h2">{c.nome}</h2>
            <p className="mt-10 text-center mx-auto max-w-prose">{c.desc}</p>
            <div className="flex items-start mt-8 flex-wrap gap-y-8 -mx-4">
              {(prodotti[c.tag] ?? []).map((p) =>
                c.separa_varianti ? (
                  <>
                    {p.variants.map((v, i) => (
                      <AccessorioCard
                        key={v.sku}
                        variantExpanded={true}
                        p={p}
                        idx={i}
                      />
                    ))}
                  </>
                ) : (
                  <AccessorioCard
                    key={p.handle}
                    variantExpanded={false}
                    p={p}
                  />
                )
              )}
            </div>
          </Transition>
        ))}
      </div>
    </Layout>
  );
};

const AccessorioCard = ({ variantExpanded, p, idx }) => {
  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  const urls = useUrls()[l];
  const mainSku = p.handle.toLowerCase();
  // TODO : variante gia selezionata
  const link = mainSku in urls ? `${urls[mainSku]}` : "";

  // ENG PRODS STORE
  const { translatedProds } = useContext(StoreContext);
  const getT = (sku) =>
    translatedProds[l] && translatedProds[l][sku.toLowerCase()]?.title;

  const tit = getTranslatedIfAvail(p.title, getT(mainSku), true);
  const sub = getTranslatedIfAvail(p.title, getT(mainSku), false);

  return (
    <Link to={link} className="w-full md:w-1/3 px-4">
      <article>
        <div className="relative">
          <div className="absolute w-full top-0 left-0">
            <GatsbyImage
              image={p.images[1].gatsbyImageData}
              alt={p.images[1].altText ?? ""}
            />
          </div>
          <GatsbyImage
            image={p.images[0].gatsbyImageData}
            alt={p.images[0].altText ?? ""}
            className="transition-opacity hover:opacity-0 duration-300"
          />
        </div>
        <h2 className="text-2xl font-bold mt-9">{`${tit}${
          variantExpanded ? " - " + p.variants[idx].title : ""
        }`}</h2>
        <p className="mt-5 font-bold">{sub}</p>
        <div className="flex items-center mt-6">
          <Stars rating={8} />
          <p className="ml-3">{tit.length} Reviews</p>
        </div>
        <p className="mt-5">{p?.sb?.desc_preview ?? p?.description}</p>
        {variantExpanded ? (
          <p className="mt-8 font-bold text-2xl">
            <strong>€ {p.variants[idx].price}</strong>
          </p>
        ) : (
          <p className="mt-8 flex gap-1 items-baseline">
            <span>da</span>
            <strong className="font-bold text-2xl">
              € {p.priceRangeV2.minVariantPrice.amount}0
            </strong>
          </p>
        )}
      </article>
    </Link>
  );
};

export default AccessoriPage;
