import { graphql } from "gatsby";
import React from "react";
import AccessoriPage from "../../components/pages/accessori";

const Accessori = (props) => <AccessoriPage {...props} />;

export const query = graphql`
  query AccessoriQueryEn {
    pagina: storyblokEntry(
      lang: { eq: "en" }
      slug: { eq: "pagina-accessori" }
    ) {
      content
    }
    shopify: allShopifyProduct(filter: { tags: { in: "accessori" } }) {
      nodes {
        handle
        id
        title
        description
        tags
        variants {
          sku
          price
          title
        }
        images {
          gatsbyImageData
          altText
        }
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
      }
    }
    sb: allStoryblokEntry(
      filter: { lang: { eq: "en" }, tag_list: { in: ["accessori"] } }
    ) {
      nodes {
        slug
        content
        tag_list
      }
    }
  }
`;

export default Accessori;
