import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import headerBrown from "../resources/images/headers/header-brown.svg";
import headerBeige from "../resources/images/headers/header-beige.svg";
import headerGreen from "../resources/images/headers/header-green.svg";
import onda from "../resources/images/headers/onda-header.svg";
import gen2 from "../resources/images/headers/gen2.json";
import useWindowSize from "./use-window-size";
import { responsiveGet } from "./utils";

const PageHeader = ({ bg, tit, sub, anim, img, posInfo }) => {
  let header;
  switch (img) {
    case "brown":
      header = headerBrown;
      break;
    case "green":
      header = headerGreen;
      break;
    case "beige":
      header = headerBeige;
      break;
    default:
      header = headerBeige;
  }

  const { width } = useWindowSize();

  const animStyle =
    posInfo ??
    responsiveGet(width, {
      def: { right: "-30px" },
      md: { top: "30px", right: "-330px" },
      lg: { right: "-60px" },
    });

  const headerPos = responsiveGet(width, {
    def: { top: "-1px", right: "0px" },
    mob: { top: "-30px", right: "-30px" },
    sm: { top: "-30px", right: "-30px" },
  });

  return (
    <header className={`h-44 ${bg} relative `}>
      <div className="ci-cont h-full flex items-center text-ci-white">
        <div>
          <p className=" text-sm font-bold">{sub}</p>
          <h1 className="mt-4 ci-h2">{tit}</h1>
        </div>
      </div>

      {/* overlays */}
      <div className="absolute overflow-hidden w-full h-full z-10 top-0 left-0">
        <img
          src={onda}
          alt=""
          className="min-w-full w-auto absolute -bottom-px left-0 z-20"
        />
        <img src={header} alt="" className="absolute z-10" style={headerPos} />
        <Player
          autoplay={true}
          loop={false}
          controls={false}
          className="absolute hidden md:block"
          style={animStyle}
          src={anim}
          keepLastFrame={true}
        />
      </div>
    </header>
  );
};

PageHeader.defaultProps = {
  bg: "bg-ci-green",
  anim: gen2,
};

export default PageHeader;
